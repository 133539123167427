angular.module("adminApp")
    .factory('aerosAdminApi', ["$http", "Upload", "CommonService", function ($http, Upload, CommonService) {

        function loadProperties() {
            return $http.get(CommonService.baseUrl + "/admin/systemProperty");
        }

        function saveProperty(property) {
            return $http.post(CommonService.baseUrl + "/admin/systemProperty", property);
        }

        function loadSystemPropertyTypes() {
            return $http.get(CommonService.baseUrl + "/admin/systemPropertyTypes");
        }

        function deleteProperty(id) {
            return $http['delete'](CommonService.baseUrl + "/admin/systemProperty/" + id);
        }

        function loadOrganizations() {
            return $http.get(CommonService.baseUrl + "/admin/api/organizations");
        }

        function deleteOrganization(id) {
            return $http["delete"](CommonService.baseUrl + "/admin/api/organizations/" + id);
        }

        function suspendOrganization(id) {
            return $http.post(CommonService.baseUrl + "/admin/api/organizations/" + id + "/suspend");
        }

        function activateOrganization(id) {
            return $http.post(CommonService.baseUrl + "/admin/api/organizations/" + id + "/activate");
        }

        function createOrganization(organization) {
            var request = {
                'organizations': [
                    organization
                ]
            };

            return $http.post(CommonService.baseUrl + "/admin/api/organizations", request);
        }

        function updateOrganization(organization) {
            return $http.put(CommonService.baseUrl + "/admin/api/organizations/" + organization.id, organization);
        }

        function loadOrganization(id) {
            return $http.get(CommonService.baseUrl + "/admin/api/organizations/" + id);
        }

        function loadOrganizationUserLogs(organizationId,username,limit,offset) {
            if (limit != undefined && offset != undefined) {
                return $http.get(CommonService.baseUrl + "/organization/" + organizationId + /users/ + username + "/debugging/jsonlogs?limit=" + limit + "&offset=" + offset);
            } else {
                return $http.get(CommonService.baseUrl + "/organization/" + organizationId + /users/ + username + "/debugging/jsonlogs");
            }
        }

        function setOrganizationUserDebugging(organizationId,username,durationInHours) {
            return $http.post(CommonService.baseUrl + "/organization/" + organizationId + /users/ + username + "/debugging/jsonlogging", { debugSessionDurationInHours: durationInHours });
        }

        function inviteUser(user, license) {
            var request = {
                user: user,
                license: license
            };
            return $http.post(CommonService.baseUrl + "/organization/userInvitations", request);
        }

        function deleteUser(username, orgId) {
            return $http['delete'](CommonService.baseUrl + "/admin/organization/" + orgId + "/users/" + encodeURIComponent(username));
        }

        function sendResetEmail(username) {
            return $http.post(CommonService.baseUrl + "/sendResetEmail/" + encodeURIComponent(username));
        }

        function userInvitationEmail(username, orgId) {
            return $http.get(CommonService.baseUrl + "/admin/sendMail/userInvitation?username=" + encodeURIComponent(username) + "&orgid=" + orgId);
        }

		function uploadRogueSoftware(file, fields) {
			return Upload.upload({
				url: CommonService.baseUrl + '/admin/software/',

				fields: fields,
				file: file
			});
		}


		function editRogueSoftware(packageName, fields, file) {
			var data = {
				softwareName: fields.softwareName,
				softwareDescription: fields.softwareDescription,
                metadataValue: fields.metadataValue,
				deleteIcon: fields.deleteIcon,
                geminiApp:  fields.geminiApp
			};
			return Upload.upload({
				method: 'POST',
				url: CommonService.baseUrl + '/admin/software/'+encodeURIComponent(packageName),
				file: file,
				data:data,
			});
			//var request = {
			//		softwareName: fields.softwareName,
			//        softwareDescription: fields.softwareDescription
			//    };
			//    return $http.put("/admin/software/"+encodeURIComponent(packageName), request);
		}

		function checkPackageNameExist(packageName) {
			return $http.get(CommonService.baseUrl + "/admin/software/package/"+encodeURIComponent(packageName));
		}

        function listRogueSoftware() {
            return $http.get(CommonService.baseUrl + '/admin/software/');
        }

        function deleteRogueSoftware(file) {
            return $http["delete"](CommonService.baseUrl + "/admin/software/" + file);
        }

        function getUploadUrl() {
            return $http.get(CommonService.baseUrl + '/admin/getUploadURL');
        }

        function addLicenseToOrganization(orgId, key) {
            return $http.post(CommonService.baseUrl + "/admin/organization/" + orgId + "/license/" + key);
        }


        function addUserToLicense(orgId, key, username, role) {
            //User needs username, orgId and string[] roles
            var user = {
                email: username,
                organizationId: orgId,
                roles: [role]
            };
            var request = {
                user: user
            };
            return $http.post(CommonService.baseUrl + "/admin/license/" + key + "/user", request);
        }

        function removeUserFromLicense(key, username) {
            return $http['delete'](CommonService.baseUrl + "/admin/license/" + key + "/user/" + username);
        }

        function getLicenseTerms() {
            return $http.get(CommonService.baseUrl + "/admin/license/licenseTerms");
        }

        function generateLicense(newLicenseModel) {
            return $http.post(CommonService.baseUrl + "/admin/license/generate/" + newLicenseModel.numLicenses, newLicenseModel);
        }

        function getFilteredLicensesList(config) {
            var url = CommonService.baseUrl + '/admin/license/search';
            return $http.post(url, config);
        }

        function editContact(orgId, contactId, request) {
            if (contactId) {
                return $http.put(CommonService.baseUrl + "/admin/organization/" + orgId + "/contacts/" + contactId, request);
            } else {
                return $http.post(CommonService.baseUrl + "/admin/organization/" + orgId + "/contacts", request);
            }
        }

        function getAdminUsers() {
            return $http.get(CommonService.baseUrl + "/admin/api/users");
        }

        function getAdminUsersFilteredUrl() {
            if( CommonService.baseUrl != "" ) {
                return CommonService.baseUrl + "/admin/api/usersFiltering";
            } else {
                return window.location.origin + "/admin/api/usersFiltering";
            }
        }

        function getAdminUsersFiltered(filter) {
            return $http.post(CommonService.baseUrl + "/admin/api/usersFiltering", filter);
        }

        function getAdminUserProfile() {
            return $http.get(CommonService.baseUrl + "/admin/api/userprofile");
        }

        function updateAdminUserProfile(user) {
            return $http.put(CommonService.baseUrl + "/admin/api/userprofile", user);
        }

        function inviteAdminUser(user) {
            var request = {
                user: user
            };
            return $http.post(CommonService.baseUrl + "/admin/api/users", request);
        }

        function updateAdminUser(user) {
            var request = {
                user: user
            };
            return $http.put(CommonService.baseUrl + "/admin/api/user", request);
        }

        function getAdminUser(username) {
            return $http.get(CommonService.baseUrl + "/admin/api/user/" + username);
        }

        function deleteAdminUser(username) {
            return $http['delete'](CommonService.baseUrl + "/admin/api/user/" + username);
        }

        function getRoles() {
            return $http.get(CommonService.baseUrl + "/api/roles");
        }

        function getAdminGroups() {
            return $http.get(CommonService.baseUrl + "/admin/api/groups");
        }

        function getAllSystemMessages() {
            return $http.get(CommonService.baseUrl + '/admin/api/messages'); // NOTE: currently returns only active messages

        }

        function createSystemMessage(msg) {
            return $http.post(CommonService.baseUrl + '/admin/api/message', [msg]);
        }

        function updateSystemMessage(msg) {
            return $http.put(CommonService.baseUrl + '/admin/api/message', [msg]);
        }

        function deleteSystemMessage(msg) {
            return $http['delete'](CommonService.baseUrl + "/admin/api/message/" + msg.id);
        }

        function getSystemLockTypes() {
            return $http.get(CommonService.baseUrl + '/admin/system/lock/types');
        }

        function lockSystemType(type) {
            return $http.put(CommonService.baseUrl + '/admin/system/lock/' + type);
        }

        function unlockSystemType(type) {
            return $http.put(CommonService.baseUrl + '/admin/system/unlock/' + type);
        }

        // ---> Notification Registrations <---

        function getAllNotificationRegistrations() {
            return $http.get(CommonService.baseUrl + '/admin/api/notificationregistrations');
        }

        function getNotificationRegistrations(id) {
            return $http.get(CommonService.baseUrl + '/admin/api/notificationregistrations/' + id);
        }

        function addNotificationRegistrations(data) {
            return $http.post(CommonService.baseUrl + '/admin/api/notificationregistrations', data);
        }

        function deleteNotificationRegistrations(id) {
            return $http.delete(CommonService.baseUrl + '/admin/api/notificationregistrations/' + id);
        }

        function updateNotificationRegistrations(model) {
            return $http.put(CommonService.baseUrl + '/admin/api/notificationregistrations/' + model.id, model);
        }

        // ---> End Notification Registrations <---

        var vendorApi = {
            list: function () {
                return $http.get(CommonService.baseUrl + '/admin/api/vendor');
            },
            create: function (vendor) {
                return $http.post(CommonService.baseUrl + '/admin/api/vendor', vendor);
            },
            update: function (vendor) {
                return $http.put(CommonService.baseUrl + '/admin/api/vendor/' + vendor.id, vendor);
            },
            addLogo: function (vendorId, file) {
                return Upload.upload({
                    url: CommonService.baseUrl + '/admin/api/vendor/' + vendorId + '/logo',
                    fields: {},
                    file: file
                });
            },
            deleteLogo: function (vendorId) {
                return $http.delete(CommonService.baseUrl + '/admin/api/vendor/' + vendorId + '/logo');
            }
        };

        var featureApi = {
            list: function () {
                return $http.get(CommonService.baseUrl + '/admin/api/feature');
            },
            create: function (feature) {
                return $http.post(CommonService.baseUrl + '/admin/api/feature', feature);
            },
            update: function (feature) {
                return $http.put(CommonService.baseUrl + '/admin/api/feature/' + feature.name, feature);
            },
            delete: function (feature) {
                return $http.delete(CommonService.baseUrl + '/admin/api/feature/' + feature.name);
            },
            getByFeatureName: function (featureName) {
                return $http.get(CommonService.baseUrl + '/admin/api/feature/' + featureName);
            },
            listByOrganization: function (organizationId) {
                return $http.get(CommonService.baseUrl + '/admin/api/organization/' + organizationId + '/features');
            },
            updateOrganization: function(organizationId, features) {
                return $http.put(CommonService.baseUrl + '/admin/api/organization/' + organizationId + '/features', features);
            }
        };

        const vaultApi = {
            post: () => $http.post(`${CommonService.baseUrl}/vault`),
            update: () => $http.post(`${CommonService.baseUrl}/vault/update`),
            filter: (filter) => $http.post(`${CommonService.baseUrl}/vault/filter`, filter),
            fetchAttachment: (id) => $http.get(`${CommonService.baseUrl}/vault/attach/${id}`),
            removeAttachment: (id) => $http.delete(`${CommonService.baseUrl}/vault/attach/${id}`)
        };

        return {
            loadOrganizations: loadOrganizations,
            loadOrganization: loadOrganization,
            loadOrganizationUserLogs: loadOrganizationUserLogs,
            setOrganizationUserDebugging: setOrganizationUserDebugging,
            deleteOrganization: deleteOrganization,
            suspendOrganization: suspendOrganization,
            activateOrganization: activateOrganization,
            createOrganization: createOrganization,
            updateOrganization: updateOrganization,
            inviteUser: inviteUser,
            deleteUser: deleteUser,
            sendResetEmail: sendResetEmail,
            userInvitationEmail: userInvitationEmail,
            uploadRogueSoftware: uploadRogueSoftware,
			editRogueSoftware: editRogueSoftware,
			checkPackageNameExist: checkPackageNameExist,
            listRogueSoftware: listRogueSoftware,
            deleteRogueSoftware: deleteRogueSoftware,
            getUploadUrl: getUploadUrl,
            loadProperties: loadProperties,
            saveProperty: saveProperty,
            deleteProperty: deleteProperty,
            loadSystemPropertyTypes: loadSystemPropertyTypes,

            editContact: editContact,
            addLicenseToOrganization: addLicenseToOrganization,
            addUserToLicense: addUserToLicense,
            removeUserFromLicense: removeUserFromLicense,
            getLicenseTerms: getLicenseTerms,
            generateLicense: generateLicense,
            getFilteredLicensesList: getFilteredLicensesList,

            //MANAGE USERS
            getAdminUsers: getAdminUsers,
            getAdminUsersFilteredUrl: getAdminUsersFilteredUrl,
            getAdminUsersFiltered: getAdminUsersFiltered,
            getAdminUserProfile: getAdminUserProfile,
            updateAdminUserProfile: updateAdminUserProfile,
            inviteAdminUser: inviteAdminUser,
            updateAdminUser: updateAdminUser,
            getAdminUser: getAdminUser,
            deleteAdminUser: deleteAdminUser,
            getRoles: getRoles,

            //MANAGE GROUPS
            getAdminGroups: getAdminGroups,

            // MANAGE MESSAGES
            getAllSystemMessages: getAllSystemMessages,
            createSystemMessage: createSystemMessage,
            updateSystemMessage: updateSystemMessage,
            deleteSystemMessage: deleteSystemMessage,

            // MANAGE SYSTEM LOCK
            getSystemLockTypes: getSystemLockTypes,
            lockSystemType: lockSystemType,
            unlockSystemType: unlockSystemType,


            // Notification Registrations
            getAllNotificationRegistrations: getAllNotificationRegistrations,
            getNotificationRegistrations: getNotificationRegistrations,
            addNotificationRegistrations: addNotificationRegistrations,
            deleteNotificationRegistrations: deleteNotificationRegistrations,
            updateNotificationRegistrations: updateNotificationRegistrations,

            vendorApi: vendorApi,
            featureApi: featureApi,
            vaultApi
        };
    }]);
